import React, { useState } from 'react';
import dynamic from 'next/dynamic';

import axios, { API } from 'lib/config/axios';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DarkButton from 'views/common/components/Logical/Layout/DarkButton';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/features/ui-slice';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

const CurrencySelectorDialog = dynamic(() => import(
  'views/common/components/Logical/Layout/TopBar/CurrencySelectorDialog'
));

const CurrencySelector = () => {
  const handleErrorResponse = useErrorsHandler();
  const currentCurrency = useSelector(
    (state) => state.globalData.appState?.current_currency,
  );
  const authenticityToken = useSelector(
    (state) => state.globalData.appState?.authenticity_token,
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const handleCurrencyButtonClick = () => {
    if (currencies && currencies.length === 0) {
      dispatch(setLoading(true));
      axios
        .get(API.currencies.index)
        .then((res) => {
          const { data } = res;
          if (data.currencies) {
            setCurrencies(data.currencies);
            setOpen(true);
          }
        })
        .catch((error) => {
          handleErrorResponse(error);
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      setOpen(true);
    }
  };

  const changeCurrency = async (newValue) => {
    axios
      .post(API.currencies.change, {
        currency_id: newValue.id,
        authenticity_token: authenticityToken,
      })
      .then(() => window.location.reload())
      .catch((error) => handleErrorResponse(error))
      .finally(() => dispatch(setLoading(false)));
  };

  const handleCurrencyClose = (e, newValue) => {
    if (newValue) {
      setLoading(true);
      changeCurrency(newValue);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <DarkButton
        variant="contained"
        color="secondary"
        aria-label="currency selector"
        onClick={handleCurrencyButtonClick}
        edge="start"
        size="medium"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation>
        <Typography variant="button">{currentCurrency?.symbol}</Typography>
      </DarkButton>
      {open && (
        <CurrencySelectorDialog
          id="currency-menu"
          currencies={currencies}
          maxWidth="xl"
          keepMounted
          open={open}
          handleCurrencyClose={handleCurrencyClose}
        />
      )}
    </>
  );
};

export default CurrencySelector;
