import React from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import axios, { API } from 'lib/config/axios';
import { useTranslation } from 'next-i18next';
import { mapNamedLocaleToCountryCode } from 'lib/data/localesMap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DarkButton from 'views/common/components/Logical/Layout/DarkButton';
import getCountryFlag from 'lib/utils/getCountryFlag';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'redux/features/ui-slice';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { CURRENT_APP } from 'lib/navigation/apps';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';

const CountrySelectorDialog = dynamic(() => import('views/common/components/Logical/Layout/TopBar/CountrySelectorDialog'));

const LanguageSelector = () => {
  const appNavigator = useAppNavigator();
  const dispatch = useDispatch();
  const hreflangData = useSelector(
    (state) => state.globalData.hreflangData?.urls,
  );
  const { t } = useTranslation();
  const appState = useSelector((state) => state.globalData.appState);
  const handleErrorResponse = useErrorsHandler();
  const [open, setOpen] = React.useState(false);

  const handleClickCountry = () => {
    setOpen(true);
  };

  const changeCountry = (body, country) => {
    dispatch(setLoading(true));
    axios
      .post(API.countries.change, body)
      .then(() => {
        const { url } = hreflangData.find((item) => (country.language_code === 'en'
          ? item.hreflang === 'x-default'
          : item.hreflang === country.language_code));
        const urlWithNoQueries = url.split('?')[0];
        appNavigator.push({
          url: urlWithNoQueries + window.location.search,
          app: CURRENT_APP,
        });
      })
      .catch((error) => {
        handleErrorResponse(error);
      })
      .finally(() => dispatch(setLoading(false)));
  };
  const handleClose = (e, newValue) => {
    setOpen(false);
    if (newValue?.language_code) {
      changeCountry(
        {
          country_id: newValue.id,
          authenticity_token: appState.authenticity_token,
        },
        newValue,
      );
    }
  };

  return (
    <>
      <DarkButton
        variant="contained"
        color="secondary"
        aria-label="country selector"
        onClick={handleClickCountry}
        edge="start"
        size="medium"
        endIcon={<KeyboardArrowDownIcon />}
        disableElevation
        style={{ fontSize: '18px' }}>
        <Image
          src={getCountryFlag(mapNamedLocaleToCountryCode[appNavigator.locale])}
          alt={`${mapNamedLocaleToCountryCode[appNavigator.locale]} flag image`}
          width={30}
          height={17}
          className="flag"
        />
      </DarkButton>
      {open && (
        <CountrySelectorDialog
          keepMounted
          open={open}
          onClose={handleClose}
          title={t('fe_er_common_layout:select_language')}
        />
      )}
    </>
  );
};

export default LanguageSelector;
