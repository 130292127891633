/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
// Material UI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// material-ui-popup-state Components
import {
  usePopupState,
  bindHover,
  bindPopper,
} from 'material-ui-popup-state/hooks';
import Image from 'next/image';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&.listItem': {
    height: '100%',
    width: 'auto',
    display: 'inline-flex',
    padding: '0 !important',
    flex: '0 0 auto',
    position: 'unset',
  },
  '&.logoItem': {
    width: `${theme.typography.pxToRem(107)} !important`,
    height: 'auto !important',
    [theme.breakpoints.down('xl')]: {
      width: `${theme.typography.pxToRem(82)} !important`,
    },
    '& .logoItemText': {
      position: 'absolute',
      zIndex: '999',
      top: `${theme.typography.pxToRem(-42)}`,
      left: 0,
      margin: '0 !important',
    },
  },
  '& .NMULogoImg': {
    '& >span': {
      [theme.breakpoints.down('xl')]: {
        width: `${theme.typography.pxToRem(82)} !important`,
      },
    },
  },
  '& .listItemText': {
    height: '100%',
    margin: '0 !important',
  },
  '& .listItemLink': {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '0.9375rem',
    alignItems: 'center',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(2, 1),
    borderBottom: '2px solid transparent',
    transition: 'border-color 200ms ease-in',
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.typography.pxToRem(13),
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderRadius: '0',
    },
    '& .menuArrow': {
      margin: `0 ${theme.typography.pxToRem(4)}`,
      transition: 'transform 200ms ease-in',
      fontSize: theme.spacing(2),
    },
  },
  '& .rotate': {
    transform: 'rotate(-180deg)',
  },
  '& .NMIFilledMembership': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.dark,
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 4,
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '& .submenu': {
    padding: '2rem 0 1.5rem',
  },
  '& .subMenuGroub': {
    width: '33.333%',
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
  '& .submenuItem': {
    width: '100%',
    padding: '0 !important',
    backgroundColor: 'transparent !important',
    justifyContent: 'stretch',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '& .submenuLink': {
    padding: '0.4rem 1rem 0.4rem 0',
    fontSize: '1rem',
    transition: 'color 150ms ease',
    display: 'inline-flex',
    color: theme.palette.common.fogWhite,
    '& .submenuArrow': {
      width: '0.8rem',
      marginRight: theme.typography.pxToRem(4),
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  '& .submenuHeading': {
    fontSize: '1.125rem',
    fontWeight: 500,
    outline: 'none',
    letterSpacing: theme.typography.pxToRem(0.5),
    color: theme.palette.common.fogWhite,
  },
  '& .submenuIcon': {
    width: '3rem',
  },
}));
const hasTouch = () => {
  if (process.browser) {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query) {
      return window.matchMedia(query).matches;
    };
    if (
      'ontouchstart' in window
      || (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
      return true;
    }
    const query = ['(', prefixes.join('touch-enabled),('), 'nomatch', ')'].join(
      '',
    );
    return mq(query);
  }
  return false;
};

const NavigationMenuItem = (props) => {
  const { item, onItemClick } = props;
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: 'popper', popupId: item.id });

  const bindTrigger = useMemo(() => {
    let handler = popupState.open;
    if (popupState.isOpen) {
      handler = popupState.close;
    }
    return {
      onClick: handler,
      onTouchStart: handler,
    };
  }, [popupState]);

  const ListContainer = React.useMemo(
    () => React.forwardRef((props, ref) => {
      const { children, ...otherProps } = props;
      return (
        <div ref={ref} {...otherProps}>
          <Container fixed>{children}</Container>
        </div>
      );
    }),
    [],
  );
  return (
    <>
      {item.id === 'logo' ? (
        <StyledListItem className="listItem logoItem">
          <ListItemText
            disableTypography
            className="logoItemText"
            primary={(
              <NavigatorLink href={item.href} className="NMULogoImg">
                <Image
                  src={item.src}
                  width={170}
                  height={36}
                  alt={t('fe_er_common_layout:EagleRider')}
                />
              </NavigatorLink>
            )}
          />
        </StyledListItem>
      ) : (
        <StyledListItem
          className="listItem"
          aria-owns={popupState.isOpen ? 'mouse-over-popover' : undefined}
          aria-haspopup={!!item.submenu}
          {...(item.submenu
            ? hasTouch()
              ? bindTrigger
              : bindHover(popupState)
            : {})}>
          {/* If device has touch like tablets, so we will use button instead of anchor
          & bindTrigger instead of bindHover
        */}
          <ListItemText
            className="listItemText"
            disableTypography
            primary={
              item.submenu && hasTouch() ? (
                <Button
                  className="listItemLink noBorderRadius"
                  onClick={onItemClick(item)}
                  variant="text">
                  {t(item.text)}
                  {item.submenu && (
                    <KeyboardArrowDownIcon
                      className={`${'menuArrow'} ${
                        popupState.isOpen ? 'rotate' : null
                      }`}
                    />
                  )}
                  {' '}
                </Button>
              ) : (
                <NavigatorLink
                  className="listItemLink"
                  href={item.href}
                  translate={item.translate}
                  onClick={onItemClick(item)}
                  color="textPrimary"
                  target={item.extrnal ? '_blank' : '_self'}>
                  {item.id !== 'membership' ? (
                    t(item.text)
                  ) : (
                    <span className="NMIFilledMembership">{t(item.text)}</span>
                  )}
                  {item.submenu && (
                    <KeyboardArrowDownIcon
                      className={`${'menuArrow'} ${
                        popupState.isOpen ? 'rotate' : null
                      }`}
                    />
                  )}

                  {props.children}
                </NavigatorLink>
              )
            }
          />
          {item.submenu && (
            <StyledPopper
              {...bindPopper(popupState)}
              transition
              style={{ right: 0, width: '100%', height: 0 }}
              popperOptions={{
                modifiers: [
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: { padding: 0 },
                  },
                ],
              }}>
              {({ TransitionProps }) => (
                <Slide
                  {...TransitionProps}
                  timeout={{ enter: 450, exit: 800 }}
                  style={{
                    transitionDelay: !popupState.isOpen ? 150 : 50,
                  }}>
                  <Paper square elevation={0} component={ListContainer}>
                    <ClickAwayListener onClickAway={popupState.close}>
                      <MenuList
                        className="submenu"
                        component="div"
                        id="menu-list-grow">
                        {item.submenu.heading && (
                          <Typography className="submenuHeading" gutterBottom>
                            {t(item.submenu.heading)}
                          </Typography>
                        )}
                        {item.submenu.items?.map(
                          (subMenuGroub, subMenuGroubIndex) => (
                            <div
                              className="subMenuGroub"
                              key={subMenuGroubIndex}>
                              {subMenuGroub?.map((subItem) => (
                                <MenuItem
                                  component="div"
                                  className="submenuItem"
                                  key={item.text + subItem.text}
                                  onClick={popupState.close}>
                                  <NavigatorLink
                                    className="submenuLink"
                                    translate={subItem.translate}
                                    href={subItem.href}
                                    onClick={onItemClick(subItem)}
                                    color="textSecondary">
                                    <ChevronRightIcon className="submenuArrow" />
                                    {t(subItem.text)}
                                  </NavigatorLink>
                                </MenuItem>
                              ))}
                            </div>
                          ),
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Slide>
              )}
            </StyledPopper>
          )}
        </StyledListItem>
      )}
    </>
  );
};

export default NavigationMenuItem;
