/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */

const { envVars } = (require('next/config')
  && require('next/config').default()
  && require('next/config').default().publicRuntimeConfig) || {
  envVars: process.env,
};

const { EN_DOMAIN } = envVars;

const namedLocales = [
  'motorrad-mieten',
  'location-moto',
  'alquiler-de-motos',
  'noleggio-moto',
  'aluguel-de-motos',
  'en',
];

const locales = ['en', 'de', 'fr', 'it', 'no', 'pt'];

const mapNamedLocaleToLocale = {
  en: 'en',
  'motorrad-mieten': 'de',
  'location-moto': 'fr',
  'alquiler-de-motos': 'es',
  'noleggio-moto': 'it',
  'aluguel-de-motos': 'pt',
};
const mapLocaleToNamedLocale = {
  en: 'en',
  de: 'motorrad-mieten',
  fr: 'location-moto',
  es: 'alquiler-de-motos',
  it: 'noleggio-moto',
  pt: 'aluguel-de-motos',
};

const mapSubdomainToLocale = {
  [EN_DOMAIN]: 'en',
};
const mapLocaleToSubdomain = {};
const mainDomain = {
  en: EN_DOMAIN,
};

const mapNamedLocaleToCountryCode = {
  'motorrad-mieten': 'DE',
  en: 'US',
  'alquiler-de-motos': 'ES',
  'location-moto': 'FR',
  'noleggio-moto': 'IT',
  'aluguel-de-motos': 'PT',
};

const mapERLocaleToESLocale = {
  'motorrad-mieten': 'de',
  'location-moto': 'fr',
  'alquiler-de-motos': 'es',
  'noleggio-moto': 'it',
  'aluguel-de-motos': 'br',
};

const subdomainLocalesArr = ['en', 'en-CA', 'en-AU', 'en-GB', 'zh'];

const oldNamedLocales = [
  'motorverhuur',
  'motorsykkelleie',
  'motorcykeludlejninger',
  'ru',
  'ar',
  'ko',
  'sv',
  'ja',
  'en-AU',
  'en-CA',
  'en-GB',
  'zh',
];

const oldSubdomainLocales = ['en-AU', 'en-CA', 'en-GB', 'zh'];

const mapOldNamedLocaleToLocale = {
  motorverhuur: 'nl',
  motorsykkelleie: 'no',
  motorcykeludlejninger: 'da',
  ru: 'ru',
  ar: 'ar',
  ko: 'ko',
  sv: 'sv',
  ja: 'ja',
  'en-AU': 'en-AU',
  'en-CA': 'en-CA',
  'en-GB': 'en-GB',
  zh: 'zh',
};
// quick fix
const oldDomains = [
  'www.eaglerider.com.au',
  'www.eaglerider.cn',
  'www.eaglerider.co.uk',
  'www.eaglerider.ca',
  'prelive-www.eaglerider.com.au',
  'prelive-www.eaglerider.cn',
  'prelive-www.eaglerider.co.uk',
  'prelive-www.eaglerider.ca',
  'prelive-k8s-www.eaglerider.com.au',
  'prelive-k8s-www.eaglerider.cn',
  'prelive-k8s-www.eaglerider.co.uk',
  'prelive-k8s-www.eaglerider.ca',
  'eaglerider.lcom.au',
  'eaglerider.lcn',
  'eaglerider.lco.uk',
  'eaglerider.lca',
];

module.exports = {
  mapLocaleToSubdomain,
  mapSubdomainToLocale,
  mapLocaleToNamedLocale,
  mapNamedLocaleToLocale,
  mapNamedLocaleToCountryCode,
  mainDomain,
  namedLocales,
  locales,
  mapERLocaleToESLocale,
  subdomainLocalesArr,
  oldNamedLocales,
  mapOldNamedLocaleToLocale,
  oldSubdomainLocales,
  mapAllNamedLocalesToLocale: {
    ...mapOldNamedLocaleToLocale,
    ...mapNamedLocaleToLocale,
  },
  oldDomains,
};
