export const isSafari = () => {
  if (typeof window !== 'undefined' && 'navigator' in window) {
    if (
      /safari/i.test(navigator.userAgent)
      && !/chrome/i.test(navigator.userAgent)
      && !/crios/i.test(navigator.userAgent)
      && !/fxios/i.test(navigator.userAgent)
      && !/Opera|OPT\//.test(navigator.userAgent)
    ) {
      return true;
    }
  }
  return false;
};
