import { createTheme, adaptV4Theme } from '@mui/material/styles';
import theme from 'lib/config/theme';

const layoutDarkTheme = createTheme(
  adaptV4Theme({
    // Dark theme for layout only
    ...theme,
    palette: {
      type: 'dark',
      primary: {
        ...theme.palette.primary,
      },
      secondary: {
        ...theme.palette.secondary,
      },
      warning: {
        ...theme.palette.warning,
      },
      success: {
        ...theme.palette.success,
      },
      error: {
        ...theme.palette.error,
      },
      info: {
        ...theme.palette.info,
      },
      facebook: {
        ...theme.palette.facebook,
      },
      grey: {
        ...theme.palette.grey,
      },
      text: {
        secondary: 'rgba(255, 255, 255, 0.8)',
      },
      background: {
        paper: '#242424',
        default: '#ffffff',
      },
      common: {
        fogWhite: '#EBF2ED',
      },
    },
    overrides: {
      ...theme.overrides,
      MuiButton: {
        containedPrimary: {
          color: '#fff',
        },
      },
      MuiBadge: {
        colorPrimary: {
          color: '#fff',
        },
      },
    },
  }),
);

export default layoutDarkTheme;
