import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CurrencySelector from 'views/common/components/Logical/Layout/TopBar/CurrencySelector';
import CountrySelector from 'views/common/components/Logical/Layout/TopBar/CountrySelector';
import useFooterSitemap from 'views/common/hooks/useFooterSitemap';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import NewsLetter from 'views/common/components/Logical/Layout/Footer/Sitemap/NewsLetter';
import FollowUs from 'views/common/components/Logical/Layout/Footer/Sitemap/FollowUs';
import { AggregatedRatings } from 'views/common/components/Logical/Layout/Footer/Sitemap';
import { Divider } from '@mui/material';

const PREFIX = 'Sitemap';

const classes = {
  root: `${PREFIX}-root`,
  dialog: `${PREFIX}-dialog`,
  heading: `${PREFIX}-heading`,
  expandIcon: `${PREFIX}-expandIcon`,
  listItem: `${PREFIX}-listItem`,
  listItemLink: `${PREFIX}-listItemLink`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.secondary.dark,
    padding: theme.spacing(5, 1, 3),
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3, 1, 1),
    },
  },

  [`& .${classes.dialog}`]: {
    marginBottom: '1.5rem',
    '& > *': {
      width: '100%',
      justifyContent: 'space-between',
    },
  },

  [`& .${classes.heading}`]: {
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(21),
    [theme.breakpoints.down('lg')]: {
      fontWeight: 500,
      cursor: 'pointer',
      padding: theme.spacing(1.2, 0),
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(15),
      '&:hover,&.selected': {
        color: theme.palette.text.primary,
      },
    },
  },

  [`& .${classes.expandIcon}`]: {
    fontSize: '1.25rem',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  [`& .${classes.listItem}`]: {
    padding: '0',
    "& [class*='MuiListItemText-root']": {
      margin: '0 !important',
    },
  },

  [`& .${classes.listItemLink}`]: {
    display: 'flex',
    padding: theme.spacing(1.25, 0),
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
}));

const Submenu = ({ category, t }) => (
  <List dense>
    {category.submenu?.map((submenuItem) => (submenuItem.group ? (
      <ListItem className={classes.listItem}>
        <NavigatorLink
          href={submenuItem.group.href}
          style={{ width: '100%' }}
          title="looking for information ?"
          translate={category.translate}
          color="textSecondary">
          <List dense>
            {submenuItem.group.texts.map(({ text }) => (
              <ListItem
                style={{
                  width: '100%',
                  padding: '0.625rem 0rem',
                }}
                key={text}
                className={`${classes.listItem} ${classes.listItemLink}`}>
                <ListItemText primary={t(text)} />
              </ListItem>
            ))}
          </List>
        </NavigatorLink>
      </ListItem>
    ) : (
      <ListItem key={category.id} className={classes.listItem}>
        <ListItemText
          primary={(
            <NavigatorLink
              className={classes.listItemLink}
              href={submenuItem.href}
              translate={submenuItem.translate}
              color="textSecondary">
              {t(submenuItem.text)}
            </NavigatorLink>
            )}
          />
      </ListItem>
    )))}
  </List>
);

const Sitemap = () => {
  const { t } = useTranslation();
  const footerSitemap = useFooterSitemap();
  const [categories, setCategories] = React.useState(footerSitemap);
  const isCrawler = useSelector((state) => state.globalData.isCrawler);

  React.useEffect(() => {
    if (isCrawler && categories && categories.length > 0) return;
    if (footerSitemap && footerSitemap.length > 0) {
      setCategories(footerSitemap);
    }
  }, [footerSitemap]);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const toggleCategory = (category) => {
    if (!isMobileScreen) return;
    const index = categories.findIndex((c) => c.id === category.id);
    setCategories([
      // Collapse all categories except the current selected one
      ...categories.slice(0, index).map((c) => ({ ...c, isOpen: false })),
      {
        ...category,
        isOpen: !category.isOpen,
      },
      ...categories.slice(index + 1).map((c) => ({ ...c, isOpen: false })),
    ]);
  };

  return (
    <Root className={classes.root}>
      <Container maxWidth="xl">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Hidden lgUp implementation="css">
              <Grid container spacing={4}>
                <Grid item xs={6} className={classes.dialog}>
                  <CurrencySelector />
                </Grid>
                <Grid item xs={6} className={classes.dialog}>
                  <CountrySelector />
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          <Grid item lg={9} xs={12}>
            <Grid container spacing={1}>
              {categories.map((category) => (
                <Grid item key={category.id} lg={3} xs={12}>
                  <Typography
                    variant="h4"
                    component="div"
                    className={
                      classes.heading + (category.isOpen ? ' selected' : '')
                    }
                    onClick={() => toggleCategory(category)}>
                    {t(category.name)}
                    <KeyboardArrowRightIcon className={classes.expandIcon} />
                  </Typography>
                  <Hidden lgUp implementation="css">
                    <Collapse in={category.isOpen} timeout="auto" unmountOnExit>
                      <Submenu category={category} classes={classes} t={t} />
                    </Collapse>
                  </Hidden>
                  <Hidden lgDown implementation="css">
                    <Submenu category={category} classes={classes} t={t} />
                  </Hidden>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item lg={3} sm={12}>
            <FollowUs title={t('fe_er_common_layout:follow_us')} />
            <NewsLetter />
            <Divider />
            <AggregatedRatings />
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default Sitemap;
