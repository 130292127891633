import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink/NavigatorLink';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PATHS from 'lib/navigation/paths';
import MobileNavigationDrawer from 'views/common/components/Logical/Layout/MenuBar/MobileNavigationDrawer';
import NavigationMenu from 'views/common/components/Logical/Layout/MenuBar/NavigationMenu';
import useCookies from 'views/common/hooks/useCookies';
import { FE_GEO_URL } from 'views/GeoView/utils/constants';
import { ErrorBoundary, ImgBox } from 'views/common/components';

const UserDrawer = dynamic(() => import('views/common/components/Logical/Layout/TopBar/UserDrawer'));
const ForgotPasswordDialog = dynamic(() => import('views/common/components/Logical/Layout/TopBar/ForgotPasswordDialog'));
const JoinUsDrawer = dynamic(() => import('views/common/components/Logical/Layout/TopBar/JoinUsDrawer'));

const PREFIX = 'MenuBar';

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  homeLink: `${PREFIX}-homeLink`,
  esLogo: `${PREFIX}-esLogo`,
};

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  '@media print': {
    display: 'none',
  },

  [`& .${classes.appBar}`]: {
    backgroundColor: theme.palette.secondary.dark,
    color: `${theme.palette.common.fogWhite} !important`,
    zIndex: '1100',
  },

  [`& .${classes.toolbar}`]: {
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.dark,
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    minHeight: theme.typography.pxToRem(47),
    [theme.breakpoints.down('lg')]: {
      minHeight: `${theme.typography.pxToRem(44)} !important`,
    },
  },

  [`& .${classes.homeLink}`]: {
    fontSize: '1.1875rem',
    fontFamily: theme.typography.fontFamilySecondary,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.common.fogWhite,
  },

  [`& .${classes.esLogo}`]: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(20),
  },
  '& .MBMobileLogoContainer': {
    width: theme.typography.pxToRem(93),
  },
}));

const MenuBar = () => {
  const appState = useSelector((state) => state.globalData.appState);
  const [, setCookie] = useCookies();
  const isCrawler = useSelector((state) => state.globalData.isCrawler);
  const appNavigator = useAppNavigator();
  const isAuthenticated = appState.current_user?.id;
  const [forgotPass, setForgotPass] = useState({
    open: false,
    render: false,
  }); // ForgotPassword Dialog
  const [mobileOpen, setMobileOpen] = useState(false); // Mobile Navigation Drawer
  const [open, setOpen] = useState(false); // JoinUs Drawer

  const handleMobileDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleMobileDrawerClose = (item) => {
    if (item?.text?.includes('rentals')) {
      setCookie(FE_GEO_URL, `/${appNavigator.locale}${item.href.url}`, {
        path: '/',
      });
    }
    setMobileOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Root className={classes.root}>
      <AppBar position="relative" color="primary" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Paper
            sx={{
              display: { lg: 'none !important', md: 'block !important' },
              backgroundColor: 'transparent !important',
              boxShadow: 'none !important',
              backgroundImage: 'none !important',
              color: 'white !important',
            }}>
            <Box display="flex" alignItems="center">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMobileDrawerOpen}
                size="large"
                sx={{
                  padding: '0 14px !important',
                  borderRadius: '50% !important',
                }}>
                <MenuIcon />
              </IconButton>

              <NavigatorLink href={PATHS.home()}>
                <Box className="MBMobileLogoContainer">
                  <ImgBox
                    image={{
                      src: '/imgs/er-logo-horizonal-secondary-white.png',
                      alt: 'ER-logo',
                    }}
                    imgHeight="20px"
                  />
                </Box>
              </NavigatorLink>
            </Box>
            <ErrorBoundary>
              <MobileNavigationDrawer
                mobileOpen={mobileOpen}
                handleDrawerClose={handleMobileDrawerClose}
                handleDrawerOpen={handleMobileDrawerOpen}
              />
            </ErrorBoundary>
          </Paper>
          <NavigationMenu />
          <Paper
            sx={{
              display: { lg: 'none !important', md: 'block !important' },
              backgroundColor: 'transparent !important',
              boxShadow: 'none !important',
              backgroundImage: 'none !important',
              color: 'white !important',
            }}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerOpen}
              color="inherit"
              size="large"
              sx={{
                padding: '0 14px !important',
                borderRadius: '50% !important',
              }}>
              <PersonIcon />
            </IconButton>
            {!isCrawler && (
              <>
                {isAuthenticated ? (
                  <>
                    <UserDrawer
                      open={open}
                      setOpen={setOpen}
                      handleDrawerClose={handleDrawerClose}
                      promotedMembership={appState.promoted_membership}
                      currentUser={appState.current_user}
                      pendingReservationsCount={
                        appState.pending_reservations_count
                      }
                      settings={appState.settings}
                      currentCompany={appState.current_company}
                    />
                  </>
                ) : (
                  <>
                    <JoinUsDrawer
                      open={open}
                      handleDrawerClose={handleDrawerClose}
                      handleForgotPasswordOpen={() => {
                        setForgotPass({ open: true, render: true });
                      }}
                    />
                  </>
                )}
              </>
            )}
          </Paper>
        </Toolbar>
      </AppBar>
      {forgotPass.render && (
        <ForgotPasswordDialog
          open={forgotPass.open}
          onClose={() => setForgotPass({
            ...forgotPass,
            open: false,
          })}
        />
      )}
    </Root>
  );
};
export default MenuBar;
