import React from 'react';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
  '&.root': {
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.fogWhite,
    textTransform: 'uppercase',
    transition: theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[700],
    },
  },
}));

const DarkButton = ({ children, className, ...props }) => (
  <StyledMuiButton {...props} className={`${className} root`}>
    {children}
  </StyledMuiButton>
);

export default DarkButton;
