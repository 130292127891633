import { styled } from '@mui/material/styles';

import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { ImgBox } from 'views/common/components';

const StyledNavigatorLink = styled(NavigatorLink)(({ theme }) => ({
  '& .ARContainer': {
    display: 'flex',
    padding: theme.spacing(3, 0),
    gap: theme.spacing(3),
    color: theme.palette.common.fogWhite,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5.5, 0, 3, 0),
      justifyContent: 'center',
      gap: theme.spacing(6),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 0, 2.5, 0),
      gap: 0,
      justifyContent: 'space-between',
    },
    '& .ARItemContainer': {
      display: 'flex',
      alignItems: 'flex-end',
      gap: theme.spacing(2.25),
    },
    '& .ARSubtitle': {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(14),
      },
    },
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: `${theme.typography.pxToRem(30)} !important`,
  },
}));

export default StyledNavigatorLink;
