/* eslint-disable no-script-url */
import React from 'react';
import dynamic from 'next/dynamic';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Container from '@mui/material/Container';

import useHeaderMenuItems from 'views/common/hooks/useHeaderMenuItems';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import NavigationMenuItem from 'views/common/components/Logical/Layout/MenuBar/NavigationMenuItem';
import useCookies from 'views/common/hooks/useCookies';
import { FE_GEO_URL, FE_GEO_URL_ORIGIN } from 'views/GeoView/utils/constants';
import mapPathnameToBreadcrumbId from 'views/common/components/Logical/Layout/utils/mapPathnameToBreadcrumbId';

const MenuDialogs = dynamic(() => import('views/common/components/Logical/Layout/MenuBar/MenuDialogs'));

const StyledContainer = styled(Container)(({ theme }) => ({
  '&.container': {
    alignSelf: 'stretch',
    maxWidth: theme.spacing(166.25),
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  '& .list': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
  },
}));

const NavigationMenu = ({ er }) => {
  const menuItems = useHeaderMenuItems();
  const [clickedItem, setClickedItem] = React.useState(null);
  const [, setCookie] = useCookies();
  const appNavigator = useAppNavigator();
  // Check clicked menu items to open dialogs if necessarily
  const handleItemClick = (item) => (event) => {
    if (item?.text?.includes('rentals')) {
      setCookie(FE_GEO_URL, `/${appNavigator.locale}${item.href.url}`, {
        path: '/',
      });
      setCookie(FE_GEO_URL_ORIGIN, 'navbar', {
        path: '/',
      });
    }
    if (item.href?.url.includes('javascript:')) {
      event.preventDefault();
      setClickedItem(item);
    }

    if (
      typeof window !== 'undefined'
      && mapPathnameToBreadcrumbId[appNavigator.pathname]
      && item.href.url?.startsWith(appNavigator.pathname)
    ) {
      if (
        document.getElementById(
          mapPathnameToBreadcrumbId[appNavigator.pathname],
        )
      ) {
        document
          .getElementById(mapPathnameToBreadcrumbId[appNavigator.pathname])
          ?.click();
        event.preventDefault();
      }
    }
  };
  return (
    <>
      <StyledContainer fixed className="container">
        <List dense disablePadding className="list">
          {menuItems?.map((item) => (
            <NavigationMenuItem
              key={item.id}
              item={item}
              onItemClick={handleItemClick}
              er={er}
            />
          ))}
        </List>
      </StyledContainer>
      {clickedItem && (
        <MenuDialogs item={clickedItem} onClose={() => setClickedItem(null)} />
      )}
    </>
  );
};

export default NavigationMenu;
