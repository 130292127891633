/* eslint-disable no-extend-native */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-console */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useMemo } from 'react';

import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';

import { GlobalStyles } from 'views/common/components';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from 'lib/config/createEmotionCache';
import theme from 'lib/config/theme';
import rebrandingTheme from 'lib/config/rebranding-theme';
import axios, { API } from 'lib/config/axios';
import LayoutLogicWrapper from 'views/common/components/HOC/LayoutLogicWrapper';
import { store } from 'redux/app/store';
import { Provider } from 'react-redux';
import { initAirbrake, notifyAirbrake } from 'lib/config/airbrake';
import Layout from 'views/common/components/Logical/Layout';
import useAppNavigator from 'views/common/hooks/useAppNavigator';
import { disabledOgImagePages } from 'lib/data/disabledOgImagePages';
import 'react-multi-carousel/lib/styles.css';
import { HydrationWrapperApp } from 'views/common/components/HOC/HydrationWrapperApp/HydrationWrapperApp';
import getQueryCSR from 'lib/utils/getQueryCSR';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { GTM_CODE } = envVars;

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];

  // eslint-disable-next-line no-inner-declarations
  function gtag() {
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
  }
  window.gtag = gtag;
  gtag('js', new Date());

  gtag('config', GTM_CODE);
}
initAirbrake();

const clientSideEmotionCache = createEmotionCache();

// polyfill to allow dayjs tz to work on IE 11
(function () {
  if (new Date().toLocaleString().charCodeAt(0) == 8206) {
    const original = Date.prototype.toLocaleString;
    Date.prototype.toLocaleString = function () {
      return original.apply(this, arguments).replace(/\u200E/g, '');
    };
  }
}());

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const appNavigator = useAppNavigator();
  const appliedTheme = pageProps.isRebrandedTheme ? rebrandingTheme : theme;
  const shouldRenderDefaultOgImage = () => !disabledOgImagePages.includes(appNavigator.pathname);
  const metaData = useMemo(() => {
    if (pageProps.ssrRender && pageProps.serverData) {
      if (!pageProps?.serverData?.seo_values) {
        notifyAirbrake(
          `Page : "${appNavigator.asPath}" doesn't has seo values even though it's renderd on the server`,
        );
        return {};
      }
      return pageProps.serverData.seo_values;
    }
    return {};
  }, [pageProps]);

  const signViaAccessToken = () => {
    const query = getQueryCSR();
    axios
      .post(API.users.signViaAccessToken, {
        access_token: appNavigator.query.access_token,
      })
      .finally(() => {
        window.location.href = query.return_url;
      });
  };

  useEffect(() => {
    window.userAgentSuffixStr = (() => {
      const ua = navigator.userAgent;
      const n = ua.length;
      if (n > 100) return ua.slice(n - 100);
      return ua;
    })();

    window.appDeviceOs = window.appDeviceOs || 'web';
    if (pageProps.ssoPage) {
      signViaAccessToken();
    }
  }, [pageProps.ssoPage]);
  if (pageProps.ssoPage) return null;
  if (pageProps.zeroLayoutPage) return <Component {...pageProps} />;

  const renderWebsiteContent = () => (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <LayoutLogicWrapper {...pageProps}>
          <ThemeProvider theme={theme}>
            {/* <GAScript /> */}

            <LocalizationProvider dateAdapter={DateAdapter}>
              <GlobalStyles />
              {Component.getLayout ? (
                Component.getLayout(<Component {...pageProps} />)
              ) : (
                <Layout {...pageProps}>
                  <ThemeProvider theme={appliedTheme}>
                    <Component {...pageProps} />
                  </ThemeProvider>
                </Layout>
              )}
            </LocalizationProvider>
          </ThemeProvider>
        </LayoutLogicWrapper>
      </CacheProvider>
    </Provider>
  );
  return (
    <>
      <Head>
        <title>
          {metaData?.title ? metaData?.title : 'EagleRider Rentals & Tours'}
        </title>
        {typeof ReactNativeWebView === 'undefined' && (
          <meta
            name="apple-itunes-app"
            content="app-id=635802031, app-argument=eaglerider://"
          />
        )}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1"
        />
        {shouldRenderDefaultOgImage() && (
          <meta
            property="og:image"
            content={
              metaData?.og_image ? metaData?.og_image : '/imgs/og_img.jpg'
            }
          />
        )}
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={
            metaData?.description
              ? metaData?.description
              : 'The world&#39;s largest motorcycle rental and tour company with rentals starting at $29/day and bike + hotel tour packages starting at $135/day. '
          }
        />
        {metaData?.keywords && (
          <meta name="keywords" content={metaData.keywords} />
        )}
        {metaData?.og_title && (
          <meta property="og:title" content={metaData.og_title} />
        )}
        {metaData?.og_description && (
          <meta property="og:description" content={metaData.og_description} />
        )}

        {/* Default favicon */}
        <link rel="icon" href="/imgs/favicon-dark.ico" />

        {/* Favicon for dark mode */}
        <link
          rel="icon"
          type="image/x-icon"
          href="/imgs/favicon-light.ico"
          media="(prefers-color-scheme: dark)"
        />

        {/* Favicon for light mode */}
        <link
          rel="icon"
          type="image/x-icon"
          href="/imgs/favicon-dark.ico"
          media="(prefers-color-scheme: light)"
        />

        {pageProps.hreflangData && (
          <>
            <link rel="canonical" href={pageProps.hreflangData?.canonical} />
            {pageProps.hreflangData.urls?.map((local) => (
              <link
                key={local.hreflang}
                rel="alternate"
                href={local.url}
                hreflang={local.hreflang}
              />
            ))}
          </>
        )}
      </Head>

      {!pageProps.ssrRender && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_CODE}`}
            height={0}
            title="gtag"
            width={0}
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      )}
      {pageProps.ssrRender ? (
        <HydrationWrapperApp isCrawler={pageProps.ssrRender}>
          {renderWebsiteContent()}
        </HydrationWrapperApp>
      ) : (
        renderWebsiteContent()
      )}
    </>
  );
};

export default appWithTranslation(MyApp);
