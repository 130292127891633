import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';
import PATHS from 'lib/navigation/paths';
import { Box } from '@mui/material';
import { ImgBox } from 'views/common/components';

const PREFIX = 'Copyright';

const classes = {
  root: `${PREFIX}-root`,
  logo: `${PREFIX}-logo`,
  typography: `${PREFIX}-typography`,
  esLogoBox: `${PREFIX}-esLogoBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    display: 'flex',
    padding: theme.spacing(2, 4),
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2, 8),
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(4),
    },
  },

  [`& .${classes.typography}`]: {
    margin: theme.spacing(0, 6.25),
    fontWeight: 600,
    fontSize: '0.9375rem',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '1rem',
    },
  },
  [`& .${classes.logo}`]: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
      marginBottom: theme.spacing(1),
    },
  },
}));

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <Root className={`${classes.root}`}>
      <NavigatorLink href={PATHS.home()}>
        <Box className={classes.logo}>
          <ImgBox
            image={{
              src: '/imgs/Rebranding/HomePage/Logo-icon-hero.png',
              alt: 'logo icon',
            }}
            imgHeight="100%"
            imgWidth="100%"
          />
        </Box>
      </NavigatorLink>
      <Typography variant="subtitle2" className={classes.typography}>
        {t('fe_er_common_layout:copyright')}
        {' '}
        &copy;
        {new Date().getFullYear()}
        {' '}
        {t('fe_er_common_layout:EagleRider')}
        .
      </Typography>
    </Root>
  );
};

export default Copyright;
