/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import dynamic from 'next/dynamic';

import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';

const NewsLetterDialog = dynamic(() => import(
  'views/common/components/Logical/Layout/Footer/Sitemap/NewsLetterDialog'
));

const PREFIX = 'NewsLetter';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  listItem: `${PREFIX}-listItem`,
  starItem: `${PREFIX}-starItem`,
  listItemText: `${PREFIX}-listItemText`,
  form: `${PREFIX}-form`,
  textField: `${PREFIX}-textField`,
  button: `${PREFIX}-button`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2.5, 0),
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.down('xl')]: {
      textAlign: 'center',
    },
  },

  [`& .${classes.listItem}`]: {
    width: 'auto',
    display: 'inline-flex',
    margin: theme.spacing(0, 2),
    '&:first-child': {
      margin: theme.spacing(0, 2, 0, 0),
    },
    [theme.breakpoints.only('lg')]: {
      margin: theme.spacing(0, 1),
      '&:first-child': {
        margin: theme.spacing(0, 1, 0, 0),
      },
    },
    paddingTop: 0,
    paddingBottom: 0,
  },

  [`& .${classes.starItem}`]: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 0.5),
    [`& .${classes.listItemText}`]: {
      fontSize: '1rem',
      position: 'relative',
      top: theme.typography.pxToRem(4),
    },
  },

  [`& .${classes.listItemText}`]: {
    fontSize: '0.8125rem',
    fontWeight: 'bold',
    marginTop: 0,
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },

  [`& .${classes.textField}`]: {
    backgroundColor: theme.palette.grey[700],
    height: theme.typography.pxToRem(40),
    padding: theme.spacing(0, 3),
    fontSize: '0.9rem',
    cursor: 'pointer !important',
    '& input': {
      cursor: 'pointer !important',
    },
    '& input::placeholder': {
      color: '#dadada',
      opacity: 1,
    },
  },

  [`& .${classes.button}`]: {
    borderRadius: 0,
    fontWeight: 'bold',
    paddingRight: theme.typography.pxToRem(20),
    paddingLeft: theme.typography.pxToRem(20),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.secondary.main,
  },
}));

const topics = [
  {
    text: 'fe_er_common_layout:navigation.deals',
  },
  {
    star: '*',
  },
  {
    text: 'fe_er_common_layout:rides',
  },
  {
    star: '*',
  },
  {
    text: 'fe_er_common_layout:special_events',
  },
];

const NewsLetter = () => {
  const [shouldRenderDialog, setShouldRenderDialog] = React.useState(false); // Only render dialog into the DOM on demand
  const [open, setOpen] = React.useState(false); // NewsLetter Dialog
  const { t } = useTranslation();
  const handleNewsletterClick = () => {
    setShouldRenderDialog(true);
    setOpen(true);
  };
  return (
    <Root className={classes.root}>
      <Typography component="p" className={classes.title}>
        {t('fe_er_common_layout:subscribe_to_newsletter')}
      </Typography>
      <List className={classes.list} disablePadding>
        {topics.map((topic, index) => (
          <ListItem
            key={index}
            dense
            className={`${classes.listItem} ${
              topic.star ? classes.starItem : ''
            }`}
            disableGutters>
            <ListItemText
              disableTypography
              className={classes.listItemText}
              primary={t(topic.text) || topic.star}
            />
          </ListItem>
        ))}
      </List>
      <form className={classes.form} onClick={handleNewsletterClick}>
        <InputBase
          className={classes.textField}
          readOnly
          placeholder={t('fe_er_common_layout:email_address')}
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disableElevation>
          {t('fe_er_common_layout:subscribe')}
        </Button>
      </form>
      {shouldRenderDialog && (
        <NewsLetterDialog open={open} onClose={() => setOpen(false)} />
      )}
    </Root>
  );
};

export default NewsLetter;
