export const isLocalStorageAvailable = () => {
  try {
    const x = '__storage_test__';
    window.localStorage.setItem(x, x);
    window.localStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const setLocalStorageKeyeWithTTL = (key, value, ttl) => {
  if (!isLocalStorageAvailable()) return;
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl * 3600 * 1000,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};
export const getLocalStorageKeyeWithTTL = (key) => {
  const localItem = JSON.parse(window.localStorage.getItem(key));
  return localItem.expiry;
};

export const checkTTLExpiration = (key, date = new Date()) => {
  if (!isLocalStorageAvailable()) return false;
  if (window.localStorage.getItem(key) === null) {
    return false;
  }
  return getLocalStorageKeyeWithTTL(key) > date.getTime();
};
