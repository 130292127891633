import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .AAMContainer': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  '& .AAMImageText': {
    alignItems: 'center',
  },
  '& .AAMTitle': {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.secondary.main,
    lineHeight: theme.spacing(2),
  },
  '& .AAMDesc': {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.grey[300],
    lineHeight: theme.spacing(2),
  },
  '& .AAMGetBtn': {
    color: theme.palette.common.fogWhite,
    backgroundColor: '#1479F0',
    borderRadius: theme.typography.pxToRem(100),
    padding: theme.spacing(0.25, 1),
    minWidth: 'auto',
    '&:hover': {
      filter: 'brightness(93%)',
    },
  },
  '& .AAMImg': {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: theme.typography.pxToRem(11),
    backgroundColor: theme.palette.common.black,
    width: theme.typography.pxToRem(50),
    height: theme.typography.pxToRem(50),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    [theme.breakpoints.down('lg')]: {
      height: theme.typography.pxToRem(27),
      width: theme.typography.pxToRem(55),
    },
  },
}));
export default StyledBox;
