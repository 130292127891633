import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ImgBox } from 'views/common/components';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  color: theme.palette.common.fogWhite,
  '& .LFdownloadBtns': {
    '& .MuiLink-root': {
      display: 'inline-block',
    },
  },
}));

export const StyledImgBox = styled(ImgBox)(({ theme }) => ({
  '&.IBimgbox': {
    '&.LFDownload': {
      [theme.breakpoints.up('lg')]: {
        height: theme.spacing(6.5),
        width: theme.spacing(22.5),
      },
      [theme.breakpoints.down('lg')]: {
        height: theme.spacing(5.4),
        width: theme.spacing(18.75),
      },
      [theme.breakpoints.down('md')]: {
        height: theme.spacing(4.3),
        width: theme.spacing(15),
      },
    },
  },
}));

export default StyledBox;
