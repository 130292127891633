import { createTheme, adaptV4Theme } from '@mui/material/styles';

const defaultTheme = createTheme();

let theme = createTheme(
  adaptV4Theme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 320,
        md: 600,
        lg: 1024,
        xl: 1330,
        xxl: 1920,
      },
    },
    mixins: {
      toolbar: {
        '@media (min-width:0)': {
          minHeight: defaultTheme.typography.pxToRem(56),
        },
      },
    },
    palette: {
      primary: {
        light: '#F4DA95',
        main: '#EDC14F',
        dark: '#E5AC17',
        A000: '#FFFCF3',
        contrastText: '#000',
      },
      secondary: {
        light: '#777777',
        main: '#000000',
        dark: '#000000',
      },
      error: {
        light: '#F6685E',
        main: '#F44336',
        dark: '#C3352B',
        A000: '#FFF5F5',
      },
      warning: {
        light: '#F5E67E',
        main: '#F3E05E',
        dark: '#C2B34B',
      },
      success: {
        light: '#52DF97',
        main: '#4caf50',
        dark: '#1B9657',
      },
      info: {
        light: '#8BD0CB',
        main: '#00B0AA',
        dark: '#019D9D',
        800: '#017A83',
        100: '#EDF9F9',
        200: '#B2E0DC',
      },
      facebook: {
        main: '#4162a7',
      },
      grey: {
        A000: '#F9F9F9',
        50: '#EFEFEF',
        100: '#DADADA',
        200: '#AEAEAE',
        300: '#797979',
        400: '#5D5D5D',
        500: '#3A3A3A',
        600: '#303030',
        700: '#242424',
        800: '#1D1D1D',
        900: '#111111',
      },
      orange: {
        100: '#FBF3DC',
        200: '#F8E6B9',
        300: '#FFAC71',
        400: '#FF9547',
        900: '#C74900',
      },
      common: {
        fogWhite: '#EBF2ED',
      },
      text: {
        primary: 'rgba(0, 0, 0, 0.87);',
      },
      background: {
        primary: '#FFFCF3',
      },
    },
    typography: {
      fontFamily: "'Acumin Pro', sans-serif",
      fontFamilySecondary: "'DrukText', serif",
      body1: {
        fontFamily: "'Acumin Pro', sans-serif",
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.625rem',
      },
      body2: {
        fontFamily: "'Acumin Pro', sans-serif",
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '1.4375rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            fontFamily: "'Acumin Pro', sans-serif",
          },
          '.MuiOutlinedInput-input.Mui-disabled': {
            background: 'rgba(216, 216, 216, 0.15)',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          // borderRadius: 0,
        },
      },
      MuiDialogTitle: {
        root: {
          backgroundColor: '#efefef',
          alignItems: 'center',
          display: 'flex',
          padding: defaultTheme.typography.pxToRem(12),
          "& [class*='MuiTypography-root']": {
            margin: defaultTheme.spacing(0, 1),
            fontWeight: 500,
            flexGrow: 1,
            fontSize: defaultTheme.typography.pxToRem(20),
          },
        },
      },

      MuiPickersDay: {
        dayDisabled: {
          textDecoration: 'line-through',
        },
      },
    },
    props: {
      MuiLink: {
        underline: 'none',
        color: 'inherit',
      },
      MuiContainer: {
        maxWidth: 'xl',
      },
      MuiDialogTitle: {
        disableTypography: true,
      },
      MuiButton: {
        disableElevation: true,
        color: 'black',
      },
      MuiTextField: {
        color: 'secondary',
      },
    },
    spacing: (factor) => `${0.5 * factor}rem`,
  }),
);

theme.overrides = {
  MuiAlert: {
    root: {
      color: theme.palette.secondary.main,
      padding: theme.spacing(0.5, 1),
      '& $icon': {
        marginRight: theme.spacing(1),
        '& svg': {
          fontSize: '22px',
          [theme.breakpoints.down('lg')]: {
            fontSize: '20px',
          },
        },
      },
      '& $message': {
        paddingBottom: theme.spacing(0.75),
        [theme.breakpoints.down('lg')]: {
          fontSize: defaultTheme.typography.pxToRem(12),
          padding: theme.spacing(0.75, 0),
        },
      },
    },
    standardError: {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(244, 67, 54, 0.1)',
      '& $icon': {
        color: theme.palette.error.main,
      },
    },
    standardInfo: {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(139, 208, 203, 0.1)',
      '& $icon': {
        color: theme.palette.info.main,
      },
    },
    standardWarning: {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(243, 224, 94, 0.1)',
      '& $icon': {
        color: theme.palette.warning.dark,
      },
    },
    standardSuccess: {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
      '& $icon': {
        color: theme.palette.success.main,
      },
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: theme.palette.grey['100'],
    },
    light: {
      backgroundColor: theme.palette.grey['50'],
    },
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  },
};

theme.typography.h1 = {
  fontFamily: "'DrukText', sans-serif",
  fontWeight: 500,
  fontSize: '3rem',
  lineHeight: '3.75rem',
  letterSpacing: 0,
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1.75rem',
    lineHeight: '2.5rem',
  },
};

theme.typography.h2 = {
  fontFamily: "'DrukText', sans-serif",
  fontWeight: 400,
  fontSize: '2.375rem',
  lineHeight: '2.875rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
  },
};

theme.typography.h3 = {
  fontFamily: "'DrukText', sans-serif",
  fontWeight: 400,
  fontSize: '1.75rem',
  lineHeight: '2.625rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
  },
};

theme.typography.h4 = {
  fontFamily: "'DrukText', sans-serif",
  fontWeight: 400,
  fontSize: '1.3125rem',
  lineHeight: '2rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1.125rem',
    lineHeight: '2rem',
  },
};

theme.typography.h5 = {
  fontFamily: "'Acumin Pro', sans-serif",
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: '1.625rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1rem',
    lineHeight: '1.625rem',
  },
};

theme.typography.h6 = {
  fontFamily: "'Acumin Pro', sans-serif",
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
};

theme.typography.subtitle1 = {
  fontFamily: "'Acumin Pro', sans-serif",
  fontWeight: 300,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
};

theme.typography.subtitle2 = {
  fontFamily: "'Acumin Pro', sans-serif",
  fontWeight: 300,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  [theme.breakpoints.down('lg')]: {
    // mobile
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
};
// theme = responsiveFontSizes(theme);
theme = adaptV4Theme(theme);
theme.components.MuiTextField = {
  defaultProps: {
    color: 'secondary',
  },
};
theme.components.MuiButton = {
  variants: [
    {
      props: { color: 'primary' },
      style: {
        color: theme.palette.secondary.main,
      },
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: {
        color: theme.palette.common.fogWhite,
      },
    },
    {
      props: { color: 'secondary', variant: 'text' },
      style: {
        color: theme.palette.secondary.light,
      },
    },
    {
      props: { color: 'secondary', variant: 'outlined' },
      style: {
        color: theme.palette.common.black,
      },
    },
  ],
};
theme.components.MuiLink = {
  styleOverrides: {
    root: {
      color: theme.palette.common.black,
      fontWeight: 500,
    },
  },
};
export default theme;
