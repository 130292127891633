/* eslint-disable mui-unused-classes/unused-classes */
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Backdrop from '@mui/material/Backdrop';
import { styled, keyframes } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Box, alpha } from '@mui/material';

const heartBeat = keyframes`
from {
    transform: scale(1) ;
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
`;

const inOutForward = keyframes`
  0% {
    transform: translateZ(1);
    opacity: 1;
  }
  100% {
    transform: translateZ(600px);
    opacity: 0;
  }`;

const StyledBackDrop = styled(Backdrop)(({ theme, animation }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.6),
  zIndex: theme.zIndex.modal + 1,
  '& .LSOuterContainer': {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    width: theme.typography.pxToRem(200),
    position: 'absolute',
    '-webkit-perspective': '1000',
  },
  '& .LSInnerContainer': {
    animation:
      animation
      && `${animation.style} ${animation.time} ease-in-out infinite both`,
  },
  '& .LSImg': {
    width: '100%',
    height: '100%',
  },
}));

const LoadingSpinner = () => {
  const loadingSpinner = useSelector((state) => state.ui.loadingSpinner);

  const [open, setOpen] = useState(loadingSpinner);

  const [animation, setAnimation] = useState({
    style: heartBeat,
    time: '1.5s',
  });

  useEffect(() => {
    if (loadingSpinner) {
      setAnimation({
        style: heartBeat,
        time: '1.5s',
      });
      setOpen(loadingSpinner);
    } else {
      setAnimation({
        style: inOutForward,
        time: '.6s',
      });
      setTimeout(() => {
        setOpen(loadingSpinner);
      }, 200);
    }
  }, [loadingSpinner]);

  return (
    <StyledBackDrop open={open} animation={animation}>
      <Box className="LSOuterContainer">
        <Box className="LSInnerContainer">
          <Image
            src="/imgs/logo-badge.svg"
            width={200}
            height={114}
            alt="EagleRider"
            className="LSImg"
          />
        </Box>
      </Box>
    </StyledBackDrop>
  );
};

export default LoadingSpinner;
